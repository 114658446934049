<template>
    <div id="editPushButtonEventTypePage" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="editPushButtonEventType"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="alert_editPushButtonEventTypeSubTitle"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24"/>
                                                            <path d="M3.5,3 L5,3 L5,19.5 C5,20.3284271 4.32842712,21 3.5,21 L3.5,21 C2.67157288,21 2,20.3284271 2,19.5 L2,4.5 C2,3.67157288 2.67157288,3 3.5,3 Z" fill="#000000"/>
                                                            <path d="M6.99987583,2.99995344 L19.754647,2.99999303 C20.3069317,2.99999474 20.7546456,3.44771138 20.7546439,3.99999613 C20.7546431,4.24703684 20.6631995,4.48533385 20.497938,4.66895776 L17.5,8 L20.4979317,11.3310353 C20.8673908,11.7415453 20.8341123,12.3738351 20.4236023,12.7432941 C20.2399776,12.9085564 20.0016794,13 19.7546376,13 L6.99987583,13 L6.99987583,2.99995344 Z" fill="#000000" opacity="0.3"/>
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("alert_editPushButtonEventType") }}
                                                </h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form class="kt-form" novalidate="novalidate" @submit.prevent>
                                            <div class="kt-portlet__body">
                                                <div class="form-group validated">
                                                    <label for="editEventType_nameInput">{{ $t("common_name") }} *</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                        </div>
                                                        <input v-model="vName" @input="$v.vName.$touch()" @keyup="onChangeForm" type="text" class="form-control" id="editEventType_nameInput" :placeholder="$t('common_enterName')" />
                                                        <div v-if="!$v.vName.required" class="invalid-feedback">
                                                            {{ $t("error_fieldIsRequired") }}
                                                        </div>
                                                        <div v-else-if="!$v.vName.minLen" class="invalid-feedback">
                                                            {{ $t("error_minLengthMsg", [$v.vName.$params.minLen.min]) }}
                                                        </div>
                                                    </div>
                                                    <span class="form-text text-muted"> {{ $t("alert_pushButtonAlertEventTypeNameDetailInfo") }}</span>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    <button id="editEventType_updateButton" @click="onUpdateButton" type="button" class="btn btn-brand kt-margin-r-5" :disabled="$v.$invalid || !isFormDataChanged">
                                                        <i class="la la-save"></i>
                                                        {{ $t("common_saveChanges") }}
                                                    </button>
                                                    <button id="editEventType_cancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                </div>
                            </div>

                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "../site/widgets/sitetitle.vue";
import commonVueHelper from "../../helpers/commonVueHelper";

export default {
    data() {
        return {
            vName: "",
            siteId: this.$route.params.siteId,
            eventTypeId: this.$route.params.eventTypeId,
            isFormDataChanged: false
        };
    },
    created: function() {
        console.log("Component(editPushButtonEventType)::created() - called");
        const payload = {
            siteId: this.siteId,
            eventTypeId: this.eventTypeId
        };
        this.getPushButtonEventTypeById(payload);
    },
    mounted: function() {
        console.log("Component(editPushButtonEventType)::mounted() - Init metronic layout");
        KTLayout.init();
    },
    destroyed: function() {
        console.log("Component(editPushButtonEventType)::destroyed() - called");
        this.resetPushButtonEventTypeState();
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        vName: {
            required,
            minLen: minLength(2)
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentPushButtonEventType: function(eventType) {
            console.log("Component(editPushButtonEventType)::watch currentPushButtonEventType - called", eventType);
            if (eventType) {
                this.vName = eventType.name;
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentSite", "currentPushButtonEventType"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getPushButtonEventTypeById", "updatePushButtonEventType", "resetPushButtonEventTypeState"]),

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(editPushButtonEventType)::onCancelButton() - called");
            this.$router.push({ name: "pushButtonEventTypes", params: { fromAction: "cancelButton" } });
        },

        onUpdateButton() {
            console.log("Component(editPushButtonEventType)::onUpdateButton() - called");
            if (this.currentPushButtonEventType) {
                const data = {
                    siteId: this.siteId,
                    eventTypeId: this.eventTypeId,
                    name: this.vName,
                    isDefault: this.currentPushButtonEventType.isDefault
                };
                this.updatePushButtonEventType(data);
            } else {
                console.error("Component(editPushButtonEventType)::onUpdateButton() - No currentPushButtonEventType");
            }
        },

        onChangeForm() {
            this.isFormDataChanged = true;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle
    },

    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        // --
        commonVueHelper.displayConfirmModalOnLeavePage(this, "editPushButtonEventType", "update", this.isFormDataChanged, to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
